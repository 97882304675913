import { http } from '@/http/axios.js'
// 部门树
export function getDeptTree() {
  return http({
    url: '/manage/dept/tree',
    method: 'get'
  })
}
// 岗位列表
export function getJobListAPI(params) {
  // return http({
  //   url: '/manage/job/page',
  //   method: 'get',
  //   params
  // })
}
// 角色列表
export function getRoleList(params) {
  return http({
    url: '/company/basic/rdbcRole/list',
    method: 'GET',
    params
  })
}
// 分类树
export function getGoodsCategoryTreeAPI(params) {
  return http({
    url: '/manage/goods/category/tree',
    method: 'get',
    params
  })
}
// 区域树
export function getAreaTree(params) {
  return http({
    url: '/company/area/tree',
    method: 'GET',
    params
  })
}
// 组织列表
export function getOrgListAPI(params) {
  return http({
    url: '/company/organization/list',
    method: 'get',
    params
  })
}
// 调解员列表
export function getLawPeopleListAPI(params) {
  return http({
    url: '/company/law-people/list',
    method: 'get',
    params
  })
}
// 片区列表
export function getDistrictListAPI(params) {
  return http({
    url: '/company/district/list',
    method: 'get',
    params
  })
}
// 纠纷类型列表
export function getDisputeTypeListAPI(params) {
  return http({
    url: '/company/cmsRegisterDisputeType/list',
    method: 'get',
    params
  })
}